
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        














































































































#app-navbar {
  @include full-nav-bar;
}
