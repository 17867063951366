
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        






























































































































































































#app-mob-nav {
  @include mob-nav-bar;
}
